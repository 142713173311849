import React, { useState, useEffect } from 'react'
import { Table, Spin, Button, message, Card } from 'antd' // Import Tooltip from antd
import 'antd/dist/antd'
import axios from 'axios'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { useNavigate } from 'react-router-dom'

const CollegeApplication = () => {
  const counselorId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  const [isloading, setIsLoading] = useState(false)
  const [applicationsData, setApplicationsData] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    fetchStudentApplications()
  }, [counselorId, token])

  const columns = [
    {
      title: 'Student ID',
      dataIndex: 'studentId',
      key: 'studentId',
    },
    {
      title: 'College Application Id',
      dataIndex: 'collegeApplicationId',
      key: 'collegeApplicationId',
    },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    {
      title: 'Application Submitted Date',
      dataIndex: 'applicationSubmissionDate',
      key: 'applicationSubmissionDate',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Views Detail',
      key: 'view',
      render: (text, record) => (
        <Button onClick={() => gotoViewDetail(record.collegeApplicationId)}>Views Detail</Button>
      ),
    },
  ]

  const gotoViewDetail = (collegeApplicationId) => {
    localStorage.setItem('collegeApplicationId', collegeApplicationId)
    navigate('/Applied-University')
  }

  const fetchStudentApplications = async () => {
    try {
      setIsLoading(true)
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/CollegeApplication/Get-Counselor-Applications-List?counselorId=${counselorId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )

      setApplicationsData(response.data)
    } catch (error) {
      message.error(error.data.response)
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const back = () => {
    navigate('/college-Application')
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        title="College Application List"
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        <div>
          {isloading ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Table
                className="table-responsive"
                bordered
                columns={columns}
                dataSource={applicationsData}
                rowKey="collegeApplicationId"
              />
            </>
          )}
        </div>
      </Card>
    </DashboardLayout>
  )
}

export default CollegeApplication
