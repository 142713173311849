import React from 'react'
import PropTypes from 'prop-types'
import './appliedrow.scss'

const ActivityCurriculum = ({ extracurricularActivity, number }) => {
  return (
    <div>
      <p>
        <strong style={{ color: 'chocolate' }}>Activity:</strong> {number}
      </p>
      <p>
        <strong>Activity Type:</strong> {extracurricularActivity.activityType}
      </p>
      <p>
        <strong>Specific Activity:</strong> {extracurricularActivity.activitySubType}
      </p>
      <p>
        <strong>Position or Leadership Description:</strong>{' '}
        {extracurricularActivity.positionOrLeadership}
      </p>{' '}
      <p>
        <strong>Organization Name:</strong> {extracurricularActivity.organizationName}
      </p>
      <p>
        <strong>
          Please describe this activity, including what you accomplished and any recognition you
          received, etc:
        </strong>{' '}
        {extracurricularActivity.description}
      </p>
      <p>
        <strong>Participation Grades Level:</strong> {extracurricularActivity.participationGrades}
      </p>
      <p>
        <strong>Timing of Participation:</strong> {extracurricularActivity.timing}
      </p>
      <p>
        <strong>Hours Per Week:</strong> {extracurricularActivity.hoursPerWeek}
      </p>
      <p>
        <strong>Weeks Per Year:</strong> {extracurricularActivity.weeksPerYear}
      </p>
      <p>
        <strong>Recognition or Achievements:</strong>{' '}
        {extracurricularActivity.willContinueInCollege ? 'Yes' : 'No'}
      </p>
      <p>
        <strong>I intend to participate in a similar activity in college:</strong>{' '}
        {extracurricularActivity.recognitionOrAchievements}
      </p>
      <hr />
      <hr />
    </div>
  )
}
ActivityCurriculum.propTypes = {
  extracurricularActivity: PropTypes.object.isRequired,
  number: PropTypes.object.isRequired,
}

export default ActivityCurriculum
