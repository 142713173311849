// CounselorSession.js
import React from 'react'
import { Tooltip, Select, Card, Button, Form, Space, Spin, Row, Col } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { useLocation } from 'react-router-dom'
import Service from './Service' // Adjust the path as necessary

const { Option } = Select

export default function CounselorSession() {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const initialStudentId = queryParams.get('studentId') || ''

  const {
    formData,
    availableTimes,
    students,
    errors,
    isLoading,
    handleChange,
    handleSelectChange,
    handleSubmit,
  } = Service(userId, token, initialStudentId)

  return (
    <DashboardLayout>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardNavbar />
        <div className="body flex-grow-1 px-3">
          <Card className="card-professional" title="Create Sessions">
            <Form layout="vertical" onSubmitCapture={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Student ID"
                    validateStatus={errors.studentId ? 'error' : ''}
                    help={errors.studentId}
                  >
                    <Select
                      value={formData.studentId}
                      onChange={handleChange}
                      placeholder="Select a student"
                    >
                      {students.map((student) => {
                        const isDisabled = !student.hasCounselorSessions

                        return (
                          <Option
                            key={student.studentId}
                            value={student.studentId}
                            disabled={isDisabled}
                          >
                            {isDisabled ? (
                              <Tooltip title="No remaining sessions">
                                <span>{`${student.studentId}, ${student.name}`}</span>
                              </Tooltip>
                            ) : (
                              `${student.studentId}, ${student.name}`
                            )}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Select Date Time"
                    validateStatus={errors.timeId ? 'error' : ''}
                    help={errors.timeId}
                  >
                    <Select
                      value={formData.timeId}
                      onChange={handleSelectChange}
                      placeholder="Select Time"
                    >
                      {availableTimes.map((time) => (
                        <Option key={time.timeId} value={time.timeId}>
                          {new Date(time.availableTime).toLocaleString()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
                  {isLoading ? 'Please Wait...' : 'Create Session'}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </DashboardLayout>
  )
}
