import React from 'react'
import { Table, Button, Select, Modal, Card } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import Service from './Service'

const { Option } = Select

export default function TutorSession() {
  const {
    visibleTable,
    selectedCourseId,
    handleStudentChange,
    handleCourseChange,
    handleClassTypeChange,
    handleCloseModal,
    isNavbarVisible,
    studentsColumns,
    columnsAvailability,
    columnsTutors,
    modifiedTutorsData,
    handleConfirmBooking,
    handleSearch,
    bookingResponse,
    courses,
    students,
    showModal,
    selectedClassType,
    setVisibleTable,
    tutorsAvailability,
  } = Service()

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          {isNavbarVisible && <DashboardNavbar />}
          <div className="body flex-grow-1 px-3">
            {visibleTable === 'findRecord' && (
              <Card className="card-professional" title="Find Record">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label" style={{ fontSize: 'large' }}>
                      Class Type
                    </label>
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                      onChange={handleClassTypeChange}
                      value={selectedClassType}
                    >
                      <option value="">Please Select</option>
                      <option value="1">1 on 1 Class</option>
                      <option value="2">Group Class</option>
                    </select>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="mb-3">
                      <label
                        htmlFor="studentSelect"
                        className="form-label"
                        style={{ fontSize: 'large' }}
                      >
                        Student Id
                      </label>
                      <Select
                        id="studentIds"
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        onChange={handleStudentChange}
                        // style={{ width: '300px' }}
                      >
                        {students.map((student) => (
                          <Option
                            key={student.studentId}
                            value={student.studentId}
                            disabled={
                              (selectedClassType === '1' && !student.hasSingleSessions) ||
                              (selectedClassType === '2' && !student.hasGroupSessions)
                            }
                          >
                            {student.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label" style={{ fontSize: 'large' }}>
                      Subject
                    </label>
                    <select
                      className="form-select form-select-sm"
                      onChange={handleCourseChange}
                      value={selectedCourseId}
                    >
                      <option value="">Please Select</option>
                      {courses.map((course) => (
                        <option key={course.courseId} value={course.courseName}>
                          {course.courseName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <Button type="primary" onClick={handleSearch}>
                  Search
                </Button>
              </Card>
            )}

            {visibleTable === 'tutorList' && (
              <>
                <br></br>
                <Card
                  title="Tutor List"
                  extra={
                    <Button type="primary" onClick={() => setVisibleTable('findRecord')}>
                      Back to Find Record
                    </Button>
                  }
                >
                  <Table
                    className="table-responsive"
                    dataSource={modifiedTutorsData}
                    columns={columnsTutors}
                    bordered
                  />
                </Card>
              </>
            )}

            {visibleTable === 'availabilityDates' && (
              <>
                <br></br>
                <Card
                  title="Availability Date"
                  extra={
                    <Button type="primary" onClick={() => setVisibleTable('tutorList')}>
                      Back to Tutor List
                    </Button>
                  }
                >
                  <Table
                    className="table-responsive"
                    dataSource={tutorsAvailability}
                    columns={columnsAvailability}
                    bordered
                  />
                </Card>
              </>
            )}
          </div>
        </div>
      </DashboardLayout>

      <Modal
        visible={showModal}
        title="Confirm Booking"
        onCancel={handleCloseModal}
        footer={[
          <Button key="back" onClick={handleCloseModal}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmBooking}>
            Confirm
          </Button>,
        ]}
        centered
        width={600}
      >
        <div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Session Type
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {bookingResponse.sessionType}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Tutor ID
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {bookingResponse.tutorId}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Tutor-Local Time
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {new Date(bookingResponse.tutorLocalTime).toLocaleString()}
                {/* {bookingResponse.tutorLocalTime} */}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Tutor Time Zone
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {bookingResponse.tutorTimeZone}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Topic
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {bookingResponse.subject}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <small>
                <label style={{ fontSize: 'medium', textAlign: 'right' }} className="form-label">
                  Start Time
                </label>
              </small>
            </div>
            <div className="col-md-6">
              <label style={{ fontSize: 'medium', textAlign: 'left' }} className="form-label">
                {new Date(bookingResponse.startTime).toLocaleString()}
              </label>
            </div>
          </div>
          <h5>Students List</h5>
          <Table dataSource={bookingResponse.students} columns={studentsColumns} />
        </div>
      </Modal>
    </>
  )
}
