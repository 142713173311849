import React, { useState } from 'react'
import { Card, Typography, Button, message } from 'antd'
import { MailOutlined } from '@ant-design/icons'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'

const { Title, Text } = Typography

const EmailVerification = () => {
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [verificationStatus, setVerificationStatus] = useState('')
  const [token, setToken] = useState('')
  const navigate = useNavigate()

  // Extract token from URL when component mounts
  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tokenFromUrl = params.get('token')
    if (tokenFromUrl) {
      setToken(tokenFromUrl)
    } else {
      setVerificationStatus('No token found in URL.')
    }
  }, [location])

  // Function to verify email when button is clicked
  const verifyEmail = async () => {
    if (!token) return
    setLoading(true)
    try {
      const response = await axios.post(
        `https://soft.recipe4foodies.com/api/Account/Email-verification?token=${token}`,
      )
      setVerificationStatus('Email successfully verified.')
      navigate('/login', {
        state: {
          successMessage: 'Email successfully verified.',
        },
      })
    } catch (error) {
      const errorMsg = error.response?.data || 'Failed to verify email.'
      setVerificationStatus(errorMsg)
      message.error(errorMsg)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Card
        className="card-professional"
        style={{ maxWidth: '600px', padding: '20px' }}
        bordered={false}
      >
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <MailOutlined style={{ fontSize: '48px', color: '#1890ff' }} />
        </div>
        <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
          Email Verification
        </Title>
        {verificationStatus ? (
          <Text>{verificationStatus}</Text>
        ) : (
          <>
            <Text>
              Thank you for signing up for{' '}
              <Link to="https://1journey.ai/" target="_blank">
                1journey.ai
              </Link>
              . We’re excited to have you on board.
            </Text>
            <br />
            <Text>Please click the button below to verify your email.</Text>
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <Button
                type="primary"
                onClick={verifyEmail}
                loading={loading}
                disabled={!token || loading}
              >
                Verify Email
              </Button>
            </div>
          </>
        )}
      </Card>
    </div>
  )
}

export default EmailVerification
