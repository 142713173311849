import React, { useState, useEffect } from 'react'
import { Form, message, Button, Modal, Space } from 'antd'
import moment from 'moment'
import {
  getRecommendations,
  getCounselorStudents,
  getCourses,
  submitRecommendation,
} from './Service'

export const useRecommendationData = () => {
  const userId = localStorage.getItem('userId') || ''
  const token = localStorage.getItem('token')
  const [sessionType, setSessionType] = useState('')
  const [showTutorRecommendations, setShowTutorRecommendations] = useState(false)
  const [loader, setLoader] = useState(false)
  const [recommendationData, setRecommendationData] = useState([])

  const [resultData, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [students, setStudents] = useState([])
  const [courses, setCourses] = useState([])
  const [showForm, setShowForm] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [viewMode, setViewMode] = useState(false)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [form] = Form.useForm()

  useEffect(() => {
    if (token) {
      handleGetRecommendations()
      fetchStudents()
      fetchCourses()
    }
  }, [token])

  const handleGetRecommendations = async () => {
    try {
      setLoading(true)
      const response = await getRecommendations(userId, token)
      setData(response.data)
    } catch (error) {
      console.error('Error fetching recommendations:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchStudents = async () => {
    try {
      const response = await getCounselorStudents(userId, token)
      setStudents(response.data)
    } catch (error) {
      console.error('Error fetching students:', error)
    }
  }

  const fetchCourses = async () => {
    try {
      const response = await getCourses()
      setCourses(response.data)
    } catch (error) {
      console.error('Error fetching courses:', error)
    }
  }

  const handleFormSubmit = async (values) => {
    setSubmitLoading(true)
    try {
      const payload = {
        counselorId: parseInt(userId),
        studentId: values.studentId,
        sessionsCount: values.sessionsCount,
        subject: values.subject,
        sessionType: values.sessionType,
        recommendationNote: values.recommendationNote,
        tutorSessionRecommendationId: values.tutorSessionRecommendationId,
      }

      await submitRecommendation(payload, token)

      message.success('Recommendation submitted successfully!')
      setShowForm(false)
      form.resetFields()
      handleGetRecommendations()
    } catch (error) {
      console.error('Error submitting recommendation:', error)
      message.error('Failed to submit recommendation. Please try again.')
    } finally {
      setSubmitLoading(false)
    }
  }

  const handleViewDetails = (record) => {
    setSelectedRecord(record)
    setViewMode(true)
  }

  const handleBackToTable = () => {
    setViewMode(false)
    setSelectedRecord(null)
  }

  const columns = [
    { title: 'Student Id', dataIndex: 'studentId', key: 'studentId' },
    { title: 'Sessions Count', dataIndex: 'sessionsCount', key: 'sessionsCount' },
    { title: 'Subject', dataIndex: 'subject', key: 'subject' },
    { title: 'Session Type', dataIndex: 'sessionType', key: 'sessionType' },
    {
      title: 'Date Time',
      dataIndex: 'dateTime',
      key: 'dateTime',
      render: (text) => moment(text).format('MMM DD, yyyy HH:mm'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => {
        if (text === 'Granted' && record.paymentSuccess) {
          return 'Paid'
        } else if (text === 'Granted' && !record.paymentSuccess) {
          return 'Granted'
        } else {
          return text
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <Button onClick={() => handleViewDetails(record)}>View Details</Button>
        </>
      ),
    },
  ]

  const handleSessionTypeChange = (value) => {
    setSessionType(value)
    if (value === 'Counselor-Session') {
      form.setFieldsValue({ subject: 'N/A' })
    }
  }

  // Handle fetching tutor recommendations from API
  const handleTutorRecommendationClick = async () => {
    try {
      setLoader(true)
      const response = await fetch(
        `https://soft.recipe4foodies.com/api/TutorRecommendation/Counselor/GetList?counselorId=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      // Check if the response is successful (status code 200-299)
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }

      const data = await response.json()
      setRecommendationData(data)
      setShowTutorRecommendations(true)
      setShowForm(false)
    } catch (error) {
      console.error('Error fetching tutor recommendations:', error)
    } finally {
      setLoader(false)
    }
  }

  const handleRecommendClick = (recommendation) => {
    form.setFieldsValue({
      studentId: recommendation.studentId,
      tutorSessionRecommendationId: recommendation.tutorSessionRecommendationId,
    })
    setShowForm(true)
    setShowTutorRecommendations(false)
  }

  const handleBacK = () => {
    setShowTutorRecommendations(false)
    setShowForm(false)
  }

  const openReasonModal = (reason) => {
    Modal.confirm({
      title: 'Reason Note',
      content: reason,
      footer: (
        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button
            type="primary"
            onClick={() => {
              Modal.destroyAll() // Close the modal
            }}
          >
            Close
          </Button>
        </Space>
      ),
    })
  }

  const handleDeclineClick = (recommendation) => {
    Modal.confirm({
      title: 'Are you sure you want to decline this recommendation?',
      content: 'This action cannot be undone once it is declined.',
      okText: 'Yes, Decline',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          const response = await fetch(
            'https://soft.recipe4foodies.com/api/TutorRecommendation/Counselor/MarkRecommendationStatus',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                tutorSessionRecommendationId: recommendation.tutorSessionRecommendationId,
                isViewed: false,
              }),
            },
          )

          if (!response.ok) {
            throw new Error('Failed to decline recommendation')
          }

          message.success('The recommendation has been successfully marked as declined.')

          // Refresh the table data
          const updatedData = recommendationData.filter(
            (item) =>
              item.tutorSessionRecommendationId !== recommendation.tutorSessionRecommendationId,
          )
          setRecommendationData(updatedData)
        } catch (error) {
          message.error('An error occurred while declining the recommendation. Please try again.')
        }
      },
    })
  }

  return {
    resultData,
    loading,
    students,
    courses,
    showForm,
    submitLoading,
    viewMode,
    selectedRecord,
    form,
    handleFormSubmit,
    handleBackToTable,
    columns,
    setShowForm,
    sessionType,
    showTutorRecommendations,
    loader,
    handleSessionTypeChange,
    handleRecommendClick,
    handleTutorRecommendationClick,
    handleBacK,
    openReasonModal,
    handleDeclineClick,
    recommendationData,
  }
}
