import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Alert, Card, message, Modal, Input, Empty } from 'antd'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import PropTypes from 'prop-types'
import { useNavbarVisibility } from '../../Common/NavbarVisibilty'
import './appliedrow.scss'
import References from './References'
import ActivityCurriculum from './ActivityCurriculum'

const CollegeApplicationReview = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [applicationStatus, setApplicationStatus] = useState('')
  const collegeApplicationId = localStorage.getItem('collegeApplicationId')
  const token = localStorage.getItem('token')
  const [currentView, setCurrentView] = useState('ApplicationDetails')
  const [cardTitle, setCardTitle] = useState('College Application Reviews')

  useEffect(() => {
    // Fetch data from API
    fetchData()
  }, [collegeApplicationId, token])

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://soft.recipe4foodies.com/api/CollegeApplication/Counselor/Get-Student-Application-Detail?collegeApplicationId=${collegeApplicationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      setData(response.data)
      setApplicationStatus(response.data.status)
    } catch (err) {
      message.error(err.data.response)
    } finally {
      setLoading(false)
    }
  }
  const back = () => {
    if (currentView === 'ApplicationDetails') {
      localStorage.removeItem('collegeApplicationId')
      navigate('/college-application-list')
    } else {
      setCurrentView('ApplicationDetails')
      setCardTitle('College Application Reviews')
    }
  }

  const handleViewChange = (view) => {
    setCurrentView(view)
    if (view === 'Reference') {
      setCardTitle('References')
    } else if (view === 'ActivityCurriculum') {
      setCardTitle('Activity Curriculum')
    }
  }

  // Check if data exists before destructuring
  const {
    applicationRequestType,
    studentId,
    fIFSAFinancials = 'No',
    grantsScholarships = 'No',
    apCoursesTaken = 'N/A',
    applicationSubmissionDate,
    aCTScore,
    sATScore,
    toeflMarks = 'N/A',
    countryBelongs,
    status,
    appliedUniversities = [],
    references = [],
    extracurricularActivities = [],
    onlineSession = {},
  } = data || {}

  const handleMarkAsViewed = async () => {
    setIsLoading(true)
    try {
      await axios.post(
        `https://soft.recipe4foodies.com/api/CollegeApplication/Mark-As-Reviewed?collegeApplicationId=${collegeApplicationId}`,
        {},
        {
          headers: {
            accept: '*/*',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      message.success('Successfully Reviewed')
      fetchData()
    } catch (error) {
      console.error('Error marking as viewed:', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card
        loading={loading}
        title={cardTitle}
        extra={
          <Button type="default" onClick={back}>
            Back
          </Button>
        }
      >
        {currentView === 'ApplicationDetails' && (
          <>
            {applicationStatus === 'Submitted' && (
              <Alert
                message="Mark as Viewed"
                type="warning"
                showIcon
                action={
                  <Button onClick={handleMarkAsViewed} disabled={isLoading}>
                    {isLoading ? 'Please Wait...' : 'Mark as Viewed'}
                  </Button>
                }
                style={{
                  width: '40%',
                  margin: '0 auto',
                  left: '20%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                }}
              />
            )}

            {applicationStatus === 'Reviewed' && (
              <Alert
                message="Open to view more and provide feedback."
                type="info"
                showIcon
                style={{
                  width: '40%',
                  margin: '0 auto',
                  left: '20%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                }}
              />
            )}
            {/* Application Details */}
            <div className="mb-4">
              <h5>Application Details</h5>
              <div className="details-row">
                <p>
                  <strong>Student ID:</strong> {studentId}
                </p>
                <p>
                  <strong>AP Courses Taken:</strong> {apCoursesTaken}
                </p>
                <p>
                  <strong>Grants/Scholarships:</strong> {grantsScholarships}
                </p>
              </div>
              <div className="details-row">
                <p>
                  <strong>Submission Date:</strong>{' '}
                  {new Date(applicationSubmissionDate).toLocaleDateString()}
                </p>
                <p>
                  <strong>ACT Score:</strong> {aCTScore || 'N/A'}
                </p>
                <p>
                  <strong>SAT Score:</strong> {sATScore}
                </p>
              </div>
              <div className="details-row">
                <p>
                  <strong>Country:</strong> {countryBelongs}
                </p>
                <p>
                  <strong>Status:</strong>{' '}
                  <span
                    className={`badge text-bg-${
                      status === 'FeedbackGiven' ? 'success' : 'warning'
                    }`}
                  >
                    {status}
                  </span>
                </p>
                <p>
                  <strong>
                    {applicationRequestType === 'International College Application Review'
                      ? 'TOEFL Score'
                      : 'FAFSA Financials'}
                    :
                  </strong>
                  {applicationRequestType === 'International College Application Review'
                    ? toeflMarks || 'N/A'
                    : fIFSAFinancials}
                </p>
              </div>
              <div className="details-row">
                <Button type="primary" onClick={() => handleViewChange('Reference')}>
                  References
                </Button>
                <Button type="primary" onClick={() => handleViewChange('ActivityCurriculum')}>
                  Activity Curriculum
                </Button>
              </div>
            </div>

            <hr />

            {/* Applied Universities */}
            <div>
              <h5>Applied Universities</h5>
              {appliedUniversities.map((university, index) => (
                <UniversityCard
                  key={university.studentAppliedUniversityId}
                  university={university}
                  fetchData={fetchData}
                  number={index + 1}
                  applicationStatus={applicationStatus}
                />
              ))}
            </div>
            <hr />

            {/* Session Information */}
            <div className="mb-4">
              <h5>Session Information</h5>
              <p>
                <strong>Session Type:</strong> College Application Review Session
              </p>
              <p>
                <strong>Date & Time:</strong> {new Date(onlineSession.startTime).toLocaleString()}
              </p>
              <p>
                <strong>Topic:</strong> {onlineSession.topic}
              </p>
              <p>
                <strong>Join Link:</strong>{' '}
                <a href={onlineSession.joinUrl} target="_blank" rel="noopener noreferrer">
                  Join Session
                </a>
              </p>
              <p>
                <strong>Session Status:</strong>{' '}
                <span
                  className={`badge text-bg-${status === 'FeedbackGiven' ? 'success' : 'warning'}`}
                >
                  {status}
                </span>
              </p>
            </div>
          </>
        )}
        {currentView === 'Reference' && (
          <div>
            {references.length > 0 ? (
              references.map((reference, index) => (
                <References
                  key={reference.studentReferencesId || index}
                  reference={reference} // Pass `reference` object correctly
                  loading={loading}
                  onBack={() => setCurrentView('ApplicationDetails')}
                  number={index + 1}
                />
              ))
            ) : (
              <Empty description="No references available" />
            )}
          </div>
        )}
        {currentView === 'ActivityCurriculum' && (
          <div>
            {extracurricularActivities.length > 0 ? (
              extracurricularActivities.map((extracurricularActivity, index) => (
                <ActivityCurriculum
                  key={extracurricularActivity.activityId || index}
                  extracurricularActivity={extracurricularActivity}
                  onBack={() => setCurrentView('ApplicationDetails')}
                  number={index + 1}
                />
              ))
            ) : (
              <Empty description="No Activity Curriculum available" />
            )}
          </div>
        )}
      </Card>
    </DashboardLayout>
  )
}

const UniversityCard = ({ university, fetchData, number, applicationStatus }) => {
  const [feedback, setFeedback] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false) // Manage submission state
  const [modal1Open, setModal1Open] = useState(false) // Manage modal visibility
  const [currentEssay, setCurrentEssay] = useState(null) // Store the current essay for feedback
  const token = localStorage.getItem('token')

  const [isModalOpen, setIsModalOpen] = useState(false)
  const { setIsNavbarVisible } = useNavbarVisibility()
  const [universityfeedback, setUniversityFeedback] = useState('')
  const [isloading, setIsLoading] = useState(false)

  const handleEssayClick = (essay) => {
    setCurrentEssay(essay) // Set current essay on click
    setModal1Open(true) // Open the modal
  }

  const handleSubmitFeedback = async () => {
    if (!feedback.trim()) {
      message.error('Feedback is required.')
      return
    }

    setIsSubmitting(true) // Set submission state to true
    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/EssayApplication/Applied-University/Essay-Feedback',
        {
          studentEssayId: currentEssay.studentEssayId,
          feedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      message.success('Feedback submitted successfully!')
      fetchData()
      setModal1Open(false)
    } catch (error) {
      console.error('Error submitting feedback:', error)
      message.error(error.response.data)
    } finally {
      setIsSubmitting(false) // Reset submission state
      setFeedback('') // Clear the feedback input
    }
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
    setIsNavbarVisible(false)
  }

  // Handle closing the feedback modal
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setUniversityFeedback('') // Clear feedback on close
  }

  const handleFeedbackSubmit = async () => {
    if (!universityfeedback.trim()) {
      message.error('Feedback cannot be empty')
      return
    }

    setIsLoading(true) // Set submitting state

    try {
      const response = await axios.post(
        'https://soft.recipe4foodies.com/api/CollegeApplication/Give-Feedback',
        {
          studentAppliedUniversityId: university.studentAppliedUniversityId,
          feedback: universityfeedback,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      message.success('Feedback submitted successfully for University!')
      handleCloseModal()
      fetchData()
    } catch (error) {
      console.error('Error submitting feedback:', error)
      message.error('Failed to submit feedback')
    } finally {
      setIsLoading(false) // Reset submitting state
    }
  }

  const handleViewFeedback = () => {
    setIsModalOpen(true)
  }

  return (
    <div>
      <Card
        style={{ marginBottom: '10px' }}
        type="inner"
        title={university.universityName}
        extra={
          applicationStatus === 'Submitted' ? (
            <Tooltip title="Please mark the application as viewed before providing feedback.">
              <Button type="primary" disabled>
                Provide Feedback For University {number}
              </Button>
            </Tooltip>
          ) : university.isFeedBackGiven ? (
            <Button type="primary" onClick={handleViewFeedback}>
              See Feedback For University {number}
            </Button>
          ) : (
            <Button type="primary" onClick={handleOpenModal}>
              Provide Feedback For University {number}
            </Button>
          )
        }
      >
        <p>
          <strong>State:</strong> {university.universityState}
        </p>
        <p>
          <strong>Admission Type:</strong> {university.admissionType}
        </p>
        <p>
          <strong>Application Submission:</strong> {university.formOfApplicationSubmission}
        </p>
        <p>
          <strong>Deadline:</strong> {new Date(university.deadline).toLocaleString()}
        </p>
        <p>
          <strong>Feedback:</strong> {university.feedBack || 'No Feedback Given'}
        </p>
        <p>
          <strong>References:</strong> {university.noOfReferences || 'No Reference Available'}
        </p>
        <p>
          <strong>Extra Curricular Activities:</strong>{' '}
          {university.noOfExtracurricularActivities || 'No Activity Available'}
        </p>
        <p>
          <strong>SAT Score Sent:</strong> {university.sATScoreSent}
        </p>
        <p>
          <strong>Application Document:</strong>
          <Button
            type="link"
            href={`https://soft.recipe4foodies.com${university.universityFormDocumentUrl}`}
          >
            View Document
          </Button>
        </p>
        <p>
          <strong>Essays:</strong>
        </p>
        <ul>
          {university.essays.map((essay) => (
            <li key={essay.essayId}>
              <Tooltip title={essay.prompt} placement="topLeft">
                <Button type="link" onClick={() => handleEssayClick(essay)}>
                  {essay.prompt.split(' ').slice(0, 10).join(' ') +
                    (essay.prompt.split(' ').length > 10 ? '...' : '')}
                </Button>
              </Tooltip>
            </li>
          ))}
        </ul>
      </Card>

      {/* Modal for feedback */}
      <Modal
        title="Essay Details"
        open={modal1Open}
        onOk={() => (currentEssay?.isFeedbackGiven ? setModal1Open(false) : handleSubmitFeedback())}
        onCancel={() => setModal1Open(false)}
        okText={isSubmitting ? 'Submitting...' : currentEssay?.isFeedbackGiven ? 'Close' : 'Submit'}
        cancelText={currentEssay?.isFeedbackGiven ? null : 'Cancel'}
      >
        {currentEssay && (
          <div>
            <p>
              <strong>Prompt:</strong> {currentEssay.prompt}
            </p>
            <Button
              type="link"
              icon={<i className="fas fa-download" />}
              href={`https://soft.recipe4foodies.com${currentEssay.contentFileUrl}`}
              download
            >
              Download Essay
            </Button>
            <p style={{ marginTop: '20px' }}>
              <strong>Feedback:</strong>
            </p>
            {currentEssay.isFeedbackGiven ? (
              <p>{currentEssay.feedback}</p>
            ) : (
              <Input.TextArea
                rows={4}
                value={feedback}
                placeholder="Enter your feedback here..."
                onChange={(e) => setFeedback(e.target.value)}
                autoFocus
              />
            )}
          </div>
        )}
      </Modal>

      {/*Model for University Feedback */}
      <Modal
        centered
        title={
          university.isFeedBackGiven
            ? `Provided Feedback For University ${number}`
            : `Provide Feedback For University ${number}`
        }
        open={isModalOpen}
        onOk={university.isFeedBackGiven ? null : handleFeedbackSubmit}
        onCancel={handleCloseModal}
        okText={isloading ? 'Submitting...' : 'Submit'}
        confirmLoading={isloading}
        footer={university.isFeedBackGiven ? null : undefined}
      >
        {university.isFeedBackGiven ? (
          <div>
            <p>{university.feedBack || 'No feedback provided yet'}</p>
          </div>
        ) : (
          <Input.TextArea
            rows={4}
            placeholder="Enter your feedback here..."
            value={universityfeedback}
            onChange={(e) => setUniversityFeedback(e.target.value)}
          />
        )}
      </Modal>
    </div>
  )
}

UniversityCard.propTypes = {
  university: PropTypes.shape({
    studentAppliedUniversityId: PropTypes.string.isRequired,
    universityName: PropTypes.string.isRequired,
    universityState: PropTypes.string.isRequired,
    isFeedBackGiven: PropTypes.string.isRequired,
    admissionType: PropTypes.string.isRequired,
    formOfApplicationSubmission: PropTypes.string.isRequired,
    deadline: PropTypes.string.isRequired,
    feedBack: PropTypes.string,
    universityFormDocumentUrl: PropTypes.string,
    noOfExtracurricularActivities: PropTypes.string,
    noOfReferences: PropTypes.string,
    sATScoreSent: PropTypes.string,
    essays: PropTypes.arrayOf(
      PropTypes.shape({
        studentEssayId: PropTypes.string.isRequired,
        prompt: PropTypes.string.isRequired,
        contentFileUrl: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
  fetchData: PropTypes.func.isRequired,
  number: PropTypes.func.isRequired,
  applicationStatus: PropTypes.func.isRequired,
}

export default CollegeApplicationReview
