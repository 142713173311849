import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom' // Import useNavigate
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { Button } from 'antd'
import Essay from '../Essay/Essay'

const ParentCollegeApplication = () => {
  const [selectedSection, setSelectedSection] = useState(null)
  const navigate = useNavigate() // Initialize useNavigate

  const getHeaderText = () => {
    switch (selectedSection) {
      case 'Essay':
        return 'Essays'
      case 'CollegeApplication':
        return 'College Application'
      default:
        return 'College Application'
    }
  }

  const handleSectionClick = (section) => {
    setSelectedSection(section)
    if (section === 'CollegeApplication') {
      navigate('/college-application-list') // Navigate to the desired URL
    }
  }

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <b>{getHeaderText()}</b>
                {selectedSection && (
                  <Button
                    onClick={() => setSelectedSection(null)}
                    style={{ position: 'absolute', right: 10, top: 10 }}
                  >
                    Back
                  </Button>
                )}
              </div>
              <div className="card-body">
                {selectedSection === null && (
                  <div
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '30px',
                      flexWrap: 'wrap',
                    }}
                  >
                    <div
                      onClick={() => handleSectionClick('Essay')}
                      className="circle-tab circle-tab-2"
                    >
                      Essay
                    </div>
                    <div
                      onClick={() => handleSectionClick('CollegeApplication')}
                      className="circle-tab circle-tab-4"
                    >
                      College Application
                    </div>
                  </div>
                )}
                {selectedSection === 'Essay' && (
                  <div>
                    <Essay />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default ParentCollegeApplication
