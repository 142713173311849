import React from 'react'
import PropTypes from 'prop-types'
import './appliedrow.scss'

const References = ({ reference, number }) => {
  return (
    <div>
      <p>
        <strong style={{ color: 'chocolate' }}>Referencer:</strong> {number}
      </p>
      <p>
        <strong>Referencer Name:</strong> {reference.firstName} {reference.lastName}
      </p>
      <p>
        <strong>Referencer Email:</strong> {reference.email}
      </p>
      <p>
        <strong>Reference School Year:</strong> {reference.referanceSchoolYear}
      </p>{' '}
      <p>
        <strong>Profession:</strong> {reference.profession}
      </p>
      <p>
        <strong>Description:</strong> {reference.description}
      </p>
      <hr />
      <hr />
    </div>
  )
}
References.propTypes = {
  reference: PropTypes.object.isRequired,
  number: PropTypes.object.isRequired,
}

export default References
