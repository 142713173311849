import React, { useState } from 'react'
import { Table, Button, Form, Input, Select, Row, Col, Modal, Space, message } from 'antd'
import 'antd/dist/antd'
import DashboardLayout from 'src/examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'src/examples/Navbars/DashboardNavbar'
import { useRecommendationData } from './useRecommendation'

const { Option } = Select

export default function Recommendations() {
  const {
    resultData,
    loading,
    students,
    courses,
    showForm,
    submitLoading,
    viewMode,
    selectedRecord,
    form,
    handleFormSubmit,
    handleBackToTable,
    columns,
    setShowForm,
    sessionType,
    showTutorRecommendations,
    loader,
    handleSessionTypeChange,
    handleRecommendClick,
    handleTutorRecommendationClick,
    handleBacK,
    openReasonModal,
    handleDeclineClick,
    recommendationData,
  } = useRecommendationData()

  return (
    <>
      <DashboardLayout>
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
          <DashboardNavbar />
          <div className="body flex-grow-1 px-3">
            <div className="card">
              <div className="card-header" style={{ position: 'relative' }}>
                <b>Recommendations</b>
              </div>
              <div className="card-body">
                {showTutorRecommendations ? (
                  <>
                    <Button type="default" onClick={handleBacK} style={{ marginBottom: '10px' }}>
                      Back to Recommendations
                    </Button>
                    <Table
                      className="table-responsive"
                      bordered
                      loading={loading}
                      dataSource={recommendationData}
                      columns={[
                        { title: 'Student ID', dataIndex: 'studentId' },
                        { title: 'Student Name', dataIndex: 'studentName' },
                        { title: 'Tutor ID', dataIndex: 'tutorId' },
                        { title: 'Tutor Name', dataIndex: 'tutorName' },
                        {
                          title: 'Reason Note',
                          dataIndex: 'reasonNote',
                          key: 'reasonNote',
                          render: (text, record) => (
                            <Button type="link" onClick={() => openReasonModal(record.reasonNote)}>
                              View Reason
                            </Button>
                          ),
                        },
                        {
                          title: 'Suggestion Date',
                          dataIndex: 'suggestionDate',
                          render: (text) => {
                            const date = new Date(text)
                            return date.toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })
                          },
                        },
                        {
                          title: 'Recommend',
                          render: (_, record) => (
                            <Button type="primary" onClick={() => handleRecommendClick(record)}>
                              Recommend
                            </Button>
                          ),
                        },
                        {
                          title: 'Decline',
                          render: (_, record) => (
                            <Button type="default" onClick={() => handleDeclineClick(record)}>
                              Decline
                            </Button>
                          ),
                        },
                      ]}
                    />
                  </>
                ) : viewMode ? (
                  <>
                    <Button
                      type="default"
                      onClick={handleBackToTable}
                      style={{ marginBottom: '10px' }}
                    >
                      Back to Recommendations
                    </Button>
                    <div style={{ padding: '10px', fontSize: '14px' }}>
                      <p style={{ margin: '0', fontSize: '16px' }}>
                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>Student ID:</span>
                        <span style={{ fontSize: '14px', marginLeft: '10px' }}>
                          {selectedRecord.studentId}
                        </span>
                      </p>
                      <p style={{ margin: '0', fontSize: '16px', marginTop: '10px' }}>
                        <span style={{ fontSize: '14px', fontWeight: 'bold' }}>
                          Recommendation Note:
                        </span>
                        <span style={{ fontSize: '14px', marginLeft: '10px' }}>
                          {selectedRecord.recommendationNote}
                        </span>
                      </p>
                    </div>
                  </>
                ) : showForm ? (
                  <Form form={form} layout="vertical" onFinish={handleFormSubmit}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Student ID"
                          name="studentId"
                          rules={[{ required: true, message: 'Please select a student' }]}
                        >
                          <Select
                            id="studentId"
                            name="studentId"
                            placeholder="Select a student"
                            onChange={(value) => form.setFieldsValue({ studentId: value })}
                          >
                            {students.map((student) => (
                              <Option key={student.studentId} value={student.studentId}>
                                {student.studentId} - {student.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Session Type"
                          name="sessionType"
                          rules={[{ required: true, message: 'Please select session type' }]}
                        >
                          <Select
                            placeholder="Select session type"
                            onChange={handleSessionTypeChange}
                          >
                            <Option value="Counselor-Session">Counselor-Session</Option>
                            <Option value="Tutor-Session">Tutor-Session</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item
                          label="Subject"
                          name="subject"
                          rules={[
                            {
                              required: sessionType === 'Tutor-Session',
                              message: 'Please select a subject',
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select subject"
                            disabled={sessionType === 'Counselor-Session'}
                          >
                            {sessionType === 'Tutor-Session' &&
                              courses.map((course) => (
                                <Option key={course.courseId} value={course.courseName}>
                                  {course.courseName}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="No of Sessions"
                          name="sessionsCount"
                          rules={[{ required: true, message: 'Please enter number of sessions' }]}
                        >
                          <Input type="number" placeholder="Enter number of sessions" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label="Tutor Session Recommendation Id"
                          name="tutorSessionRecommendationId"
                          hidden
                        >
                          <Input type="number" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={20}>
                        <Form.Item
                          label="Recommendation Note"
                          name="recommendationNote"
                          rules={[
                            { required: true, message: 'Please enter a recommendation note' },
                          ]}
                        >
                          <Input.TextArea placeholder="Enter recommendation note" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={submitLoading}
                        disabled={submitLoading}
                      >
                        {submitLoading ? 'Submitting...' : 'Submit'}
                      </Button>
                      <Button
                        type="default"
                        onClick={() => setShowForm(false)}
                        style={{ marginLeft: '10px' }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </Form>
                ) : (
                  <>
                    <Button
                      type="primary"
                      onClick={() => setShowForm(true)}
                      style={{ marginBottom: '10px' }}
                    >
                      Add Recommendation
                    </Button>
                    <Button
                      type="primary"
                      onClick={handleTutorRecommendationClick}
                      disabled={loader}
                      style={{ marginBottom: '10px', marginLeft: '10px' }}
                    >
                      {loader ? 'Please Wait...' : 'Tutor Recommendation'}
                    </Button>
                    <Table
                      className="table-responsive"
                      bordered
                      loading={loading}
                      dataSource={resultData}
                      columns={columns}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}
